/* __next_internal_client_entry_do_not_use__ default auto */ import { useSession } from "next-auth/react";
function StaffOnly(param) {
    let { children } = param;
    var _session_user;
    const { data: session } = useSession();
    const isStaff = session === null || session === void 0 ? void 0 : (_session_user = session.user) === null || _session_user === void 0 ? void 0 : _session_user.is_staff;
    if (!isStaff) return null;
    return children;
}
export default StaffOnly;
