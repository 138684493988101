/* __next_internal_client_entry_do_not_use__ useCartStore,CartStoreProvider auto */ import createZustandContext from "@features/shared/providers/createZustandContext";
import { createStore } from "zustand";
const CartStore = createZustandContext((initialState)=>{
    return createStore()((set, get)=>({
            selectedSellingPlan: initialState.selectedSellingPlan,
            selectedVariantIds: initialState.selectedVariantIds,
            setSelectedSellingPlan: (value)=>set((state)=>{
                    return {
                        selectedSellingPlan: value
                    };
                }),
            setSelectedVariantIds: (value)=>set((state)=>{
                    return {
                        selectedVariantIds: value
                    };
                })
        }));
});
export const useCartStore = CartStore.useStore;
export const CartStoreProvider = CartStore.Provider;
