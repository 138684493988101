export default {
	token: {
		'blue.1': '#dfebf7',
		'blue.2': '#96beeb',
		'blue.3': '#6a9cde',
		'blue.4': '#437cd1',
		'blue.5': '#1f5cc4',
		'blue.6': '#003db8',
		'blue.7': '#002c91',
		'blue.8': '#001d6b',
		'blue.9': '#001045',
		'blue.10': '#00061f',
		'cyan.1': '#e6fffb',
		'cyan.2': '#b5f5ec',
		'cyan.3': '#87e8de',
		'cyan.4': '#5cdbd3',
		'cyan.5': '#36cfc9',
		'cyan.6': '#13c2c2',
		'cyan.7': '#08979c',
		'cyan.8': '#006d75',
		'cyan.9': '#00474f',
		'cyan.10': '#002329',
		'geekblue.1': '#f0f5ff',
		'geekblue.2': '#d6e4ff',
		'geekblue.3': '#adc6ff',
		'geekblue.4': '#85a5ff',
		'geekblue.5': '#597ef7',
		'geekblue.6': '#2f54eb',
		'geekblue.7': '#1d39c4',
		'geekblue.8': '#10239e',
		'geekblue.9': '#061178',
		'geekblue.10': '#030852',
		'gold.1': '#fffbe6',
		'gold.2': '#fff1b8',
		'gold.3': '#ffe58f',
		'gold.4': '#ffd666',
		'gold.5': '#ffc53d',
		'gold.6': '#faad14',
		'gold.7': '#d48806',
		'gold.8': '#ad6800',
		'gold.9': '#874d00',
		'gold.10': '#613400',
		'green.1': '#f6ffed',
		'green.2': '#d9f7be',
		'green.3': '#b7eb8f',
		'green.4': '#95de64',
		'green.5': '#73d13d',
		'green.6': '#52c41a',
		'green.7': '#389e0d',
		'green.8': '#237804',
		'green.9': '#135200',
		'green.10': '#092b00',
		'lime.1': '#fcffe6',
		'lime.2': '#f4ffb8',
		'lime.3': '#eaff8f',
		'lime.4': '#d3f261',
		'lime.5': '#bae637',
		'lime.6': '#a0d911',
		'lime.7': '#7cb305',
		'lime.8': '#5b8c00',
		'lime.9': '#3f6600',
		'lime.10': '#254000',
		'magenta.1': '#fff0f6',
		'magenta.2': '#ffd6e7',
		'magenta.3': '#ffadd2',
		'magenta.4': '#ff85c0',
		'magenta.5': '#f759ab',
		'magenta.6': '#eb2f96',
		'magenta.7': '#c41d7f',
		'magenta.8': '#9e1068',
		'magenta.9': '#780650',
		'magenta.10': '#520339',
		'orange.1': '#fff7e6',
		'orange.2': '#ffe7ba',
		'orange.3': '#ffd591',
		'orange.4': '#ffc069',
		colorText: 'rgba(0, 0, 0, 0.88)',
		colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
		'orange.5': '#ffa940',
		colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
		colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
		'orange.6': '#fa8c16',
		'orange.7': '#d46b08',
		colorTextLightSolid: '#ffffff',
		colorTextHeading: 'rgba(0, 0, 0, 0.88)',
		colorTextLabel: 'rgba(0, 0, 0, 0.65)',
		'orange.8': '#ad4e00',
		colorTextDescription: 'rgba(0, 0, 0, 0.45)',
		'orange.9': '#873800',
		colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
		'orange.10': '#612500',
		'purple.1': '#f8e6ff',
		'purple.2': '#e2b5f5',
		'purple.3': '#c887e8',
		colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
		colorIcon: 'rgba(0, 0, 0, 0.45)',
		'purple.4': '#ad5cdb',
		colorIconHover: 'rgba(0, 0, 0, 0.88)',
		colorBgContainer: '#ffffff',
		'purple.5': '#9136cf',
		colorBgElevated: '#ffffff',
		'purple.6': '#7614c2',
		colorBgLayout: '#f5f5f5',
		'purple.7': '#57089c',
		colorBgMask: 'rgba(0, 0, 0, 0.45)',
		'purple.8': '#3b0075',
		colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
		'purple.9': '#25004f',
		colorBorder: '#d9d9d9',
		'purple.10': '#120029',
		colorBorderSecondary: '#f0f0f0',
		'red.1': '#fff0f0',
		colorFill: 'rgba(0, 0, 0, 0.15)',
		'red.2': '#ffdede',
		colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
		colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
		colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
		'red.3': '#ffb5b8',
		'red.4': '#ff8c94',
		colorWhite: '#ffffff',
		'red.5': '#ff6373',
		colorBgBase: '#ffffff',
		'red.6': '#f93953',
		colorTextBase: '#000000',
		'red.7': '#d42643',
		'red.8': '#ad1735',
		colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
		'red.9': '#870b28',
		colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
		'red.10': '#5c0011',
		colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
		'volcano.1': '#fff2e8',
		colorBorderBg: '#ffffff',
		'volcano.2': '#ffd8bf',
		colorFillContent: 'rgba(0, 0, 0, 0.06)',
		'volcano.3': '#ffbb96',
		colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
		'volcano.4': '#ff9c6e',
		colorFillAlter: 'rgba(0, 0, 0, 0.02)',
		'volcano.5': '#ff7a45',
		'volcano.6': '#fa541c',
		'volcano.7': '#d4380d',
		transparent: 'rgba(0, 0, 0, 0)',
		colorSplit: 'rgba(0, 0, 0, 0.06)',
		'yellow.1': '#fffee6',
		'volcano.8': '#ad2102',
		'yellow.2': '#fff7a3',
		'yellow.3': '#fff07a',
		'volcano.9': '#871400',
		'yellow.4': '#ffe552',
		'volcano.10': '#610b00',
		'yellow.5': '#ffd829',
		'yellow.6': '#ffc700',
		'yellow.7': '#d9a300',
		'yellow.8': '#b38000',
		'yellow.9': '#8c6000',
		'yellow.10': '#664200',
		colorPrimary: '#003db8',
		colorSuccess: '#52c41a',
		colorWarning: '#faad14',
		colorInfo: '#003db8',
		colorError: '#ff4d4f',
		colorLink: '#003db8',
		colorErrorBg: '#fff2f0',
		colorErrorBgHover: '#fff1f0',
		colorErrorBorder: '#ffccc7',
		colorErrorBorderHover: '#ffa39e',
		colorErrorHover: '#ff7875',
		colorErrorActive: '#d9363e',
		colorErrorTextHover: '#ff7875',
		colorErrorText: '#ff4d4f',
		colorErrorTextActive: '#d9363e',
		colorLinkHover: '#1f5cc4',
		colorInfoBg: '#dfebf7',
		colorInfoBgHover: '#96beeb',
		colorInfoBorder: '#6a9cde',
		colorInfoBorderHover: '#437cd1',
		colorInfoHover: '#1f5cc4',
		colorInfoActive: '#002c91',
		colorInfoTextHover: '#1f5cc4',
		colorInfoText: '#003db8',
		colorInfoTextActive: '#002c91',
		colorLinkActive: '#002c91',
		colorPrimaryBg: '#dfebf7',
		colorPrimaryBgHover: '#96beeb',
		colorPrimaryBorder: '#6a9cde',
		colorPrimaryBorderHover: '#437cd1',
		colorPrimaryHover: '#1f5cc4',
		colorPrimaryActive: '#002c91',
		colorPrimaryTextHover: '#1f5cc4',
		colorPrimaryText: '#003db8',
		colorPrimaryTextActive: '#002c91',
		colorSuccessBg: '#f6ffed',
		colorSuccessBgHover: '#d9f7be',
		colorSuccessBorder: '#b7eb8f',
		colorSuccessBorderHover: '#95de64',
		colorSuccessHover: '#95de64',
		colorSuccessActive: '#389e0d',
		colorSuccessTextHover: '#73d13d',
		colorSuccessText: '#52c41a',
		colorSuccessTextActive: '#389e0d',
		colorWarningBg: '#fffbe6',
		colorWarningBgHover: '#fff1b8',
		colorWarningBorder: '#ffe58f',
		colorWarningBorderHover: '#ffd666',
		colorWarningHover: '#ffd666',
		colorWarningActive: '#d48806',
		colorWarningTextHover: '#ffc53d',
		colorWarningText: '#faad14',
		colorWarningTextActive: '#d48806',
		colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
		colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
		controlItemBgActive: '#dfebf7',
		controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
		controlItemBgActiveHover: '#96beeb',
		controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
		controlOutline: 'rgba(5, 145, 255, 0.1)',
		controlTmpOutline: 'rgba(0, 0, 0, 0.02)',
		borderRadius: 10,
		borderRadiusLG: 16,
		borderRadiusSM: 6,
		borderRadiusXS: 3,
		sizeStep: 4,
		sizeUnit: 4,
		controlInteractiveSize: 16,
		size: 16,
		sizeLG: 24,
		sizeMD: 20,
		sizeMS: 16,
		sizeSM: 12,
		sizeXL: 32,
		sizeXS: 8,
		sizeXXL: 48,
		controlHeight: 32,
		sizeXXS: 4,
		controlHeightLG: 40,
		controlHeightSM: 24,
		controlHeightXS: 16,
		lineWidth: 1,
		lineWidthBold: 2,
		lineWidthFocus: 4,
		controlOutlineWidth: 2,
		screenLG: 1000,
		screenLGMax: 1279,
		screenLGMin: 1000,
		screenMD: 800,
		screenMDMax: 999,
		screenMDMin: 800,
		screenSM: 620,
		screenSMMax: 799,
		screenSMMin: 620,
		screenXL: 1280,
		screenXLMax: 1535,
		screenXLMin: 1280,
		screenXS: 450,
		screenXSMax: 619,
		screenXSMin: 450,
		screenXXL: 1600,
		screenXXLMin: 1600,
		sizePopupArrow: 16,
		margin: 16,
		marginLG: 24,
		marginMD: 20,
		marginSM: 12,
		marginXL: 32,
		marginXS: 8,
		marginXXL: 48,
		marginXXS: 4,
		padding: 16,
		paddingLG: 24,
		paddingMD: 20,
		paddingSM: 12,
		paddingXL: 32,
		paddingXS: 8,
		paddingXXS: 4,
		paddingContentHorizontal: 16,
		paddingContentHorizontalLG: 24,
		paddingContentHorizontalSM: 16,
		paddingContentVertical: 12,
		paddingContentVerticalLG: 16,
		paddingContentVerticalSM: 8,
		controlPaddingHorizontal: 12,
		controlPaddingHorizontalSM: 8,
		fontFamily: 'var(--font-marlinsoft)',
		fontFamilyCode: 'Courier Prime',
		fontSize: 16,
		fontSizeLG: 18,
		fontSizeSM: 14,
		fontSizeXL: 20,
		fontSizeHeading1: 38,
		fontSizeHeading2: 30,
		fontSizeHeading3: 24,
		fontSizeHeading4: 20,
		fontSizeHeading5: 16,
		lineHeight: 1.375,
		lineHeightHeading1: 1.2105263157894737,
		lineHeightHeading2: 1.2666666666666666,
		lineHeightHeading3: 1.3333333333333333,
		lineHeightHeading4: 1.4,
		lineHeightHeading5: 1.5,
		lineHeightLG: 1.3333333333333333,
		lineHeightSM: 1.4285714285714286,
		fontSizeIcon: 14,
		fontWeightStrong: 600,
		colorFillAlterSolid: '#fafafa',
		fontWeightNormal: 400,
		colorFilledHandleBg: '#f0f0f0',
		colorBgSolid: '#000000',
		colorBgSolidActive: 'rgba(0, 0, 0, 0.95)',
		colorBgSolidHover: 'rgba(0, 0, 0, 0.75)',
		solidTextColor: '#ffffff',
	},
	components: {
		Input: {
			paddingInlineSM: 7,
			paddingInlineLG: 11,
			paddingInline: 11,
			paddingBlockSM: 0,
			paddingBlockLG: 7,
			paddingBlock: 4,
			paddingXXS: 4,
			paddingXS: 8,
			paddingSM: 12,
			paddingLG: 24,
			lineWidth: 1,
			lineHeightLG: 1.3333333333333333,
			lineHeight: 1.375,
			fontSizeLG: 18,
			fontSizeIcon: 14,
			fontSize: 16,
			controlPaddingHorizontalSM: 8,
			controlPaddingHorizontal: 12,
			controlOutlineWidth: 2,
			controlOutline: 'rgba(5, 145, 255, 0.1)',
			controlHeightSM: 24,
			controlHeightLG: 40,
			controlHeight: 32,
			borderRadiusSM: 6,
			borderRadiusLG: 16,
			borderRadius: 10,
			colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
			colorWarningBorderHover: '#ffd666',
			colorWarning: '#faad14',
			colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
			colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
			colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
			colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
			colorTextDescription: 'rgba(0, 0, 0, 0.45)',
			colorText: 'rgba(0, 0, 0, 0.88)',
			colorPrimary: '#003db8',
			colorIconHover: 'rgba(0, 0, 0, 0.88)',
			colorIcon: 'rgba(0, 0, 0, 0.45)',
			colorFillAlter: 'rgba(0, 0, 0, 0.02)',
			colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
			colorErrorBorderHover: '#ffa39e',
			colorError: '#ff4d4f',
			colorBorder: '#d9d9d9',
			colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
			colorBgContainer: '#ffffff',
			hoverBorderColor: '#1f5cc4',
			addonBg: 'rgba(0, 0, 0, 0.02)',
			activeBorderColor: '#003db8',
			colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
			colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
			colorErrorBgHover: '#fff1f0',
			colorErrorBg: '#fff2f0',
			colorWarningBg: '#fffbe6',
			colorWarningBgHover: '#fff1b8',
			colorWarningText: '#faad14',
			colorErrorText: '#ff4d4f',
			activeBg: '#ffffff',
			hoverBg: '#ffffff',
			inputFontSize: 16,
			inputFontSizeLG: 18,
			inputFontSizeSM: 14,
			fontFamily: 'var(--font-marlinsoft)',
		},
		Menu: {
			fontSize: 16,
			fontSizeLG: 24,
			padding: 20,
			itemHeight: 54,
			iconSize: 20,
			activeBarBorderWidth: 0,
			itemSelectedBg: 'rgb(255, 255, 255)',
			itemBorderRadius: 4,
			itemSelectedColor: 'rgb(0, 0, 0)',
			borderRadius: 4,
			boxShadowSecondary:
				'      0 1px 2px -2px rgba(0, 0, 0, 0.16),      0 3px 6px 0 rgba(0, 0, 0, 0.12),      0 5px 12px 4px rgba(0, 0, 0, 0.09)    ',
			iconMarginInlineEnd: 16,
		},
		Button: {
			contentFontSize: 18,
			contentFontSizeLG: 20,
			contentFontSizeSM: 15,
			onlyIconSize: 18,
			onlyIconSizeLG: 20,
			onlyIconSizeSM: 16,
			paddingBlock: 16,
			paddingBlockLG: 20,
			paddingInline: 32,
			paddingInlineLG: 80,
			paddingInlineSM: 22,
			controlHeight: 56,
			controlHeightLG: 64,
			controlHeightSM: 38,
			borderRadius: 28,
			borderRadiusSM: 28,
			fontWeight: 550,
			borderRadiusLG: 20,
			primaryShadow: '',
			lineWidth: 2,
			paddingBlockSM: 12,
			defaultBg: 'rgb(255,199,0)',
			defaultBorderColor: 'rgb(255,199,0)',
			defaultColor: 'rgb(0,0,0)',
			defaultHoverBg: 'rgb(241,189,0)',
			defaultHoverBorderColor: 'rgb(241,189,0)',
			defaultHoverColor: 'rgb(0,0,0)',
			defaultGhostBorderColor: 'rgb(39,39,39)',
			defaultGhostColor: 'rgb(0,0,0)',
			groupBorderColor: 'rgb(39,39,39)',
			paddingXS: 8,
			paddingContentHorizontal: 16,
			marginXS: 8,
			lineWidthFocus: 4,
			contentLineHeight: 1.375,
			controlOutlineWidth: 2,
			controlOutline: 'rgba(5, 145, 255, 0.1)',
			colorTextLightSolid: '#ffffff',
			colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
			colorText: 'rgba(0, 0, 0, 0.88)',
			colorPrimaryHover: '#1f5cc4',
			colorPrimaryBorder: '#6a9cde',
			colorPrimaryActive: '#002c91',
			colorPrimary: '#003db8',
			colorLinkHover: '#1f5cc4',
			colorLinkActive: '#002c91',
			colorLink: '#003db8',
			colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
			colorErrorHover: '#ff7875',
			colorErrorBorderHover: '#ffa39e',
			colorErrorBg: '#fff2f0',
			colorErrorActive: '#d9363e',
			colorError: '#ff4d4f',
			colorBorder: '#d9d9d9',
			colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
			colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
			colorBgContainer: '#ffffff',
			textHoverBg: 'rgba(0, 0, 0, 0.06)',
			primaryColor: '#ffffff',
			linkHoverBg: 'rgba(0, 0, 0, 0)',
			ghostBg: 'rgba(0, 0, 0, 0)',
			dangerColor: '#ffffff',
			borderColorDisabled: '#d9d9d9',
			defaultActiveBg: '#ffffff',
			defaultActiveColor: '#002c91',
			defaultActiveBorderColor: '#002c91',
			contentLineHeightLG: 1.3333333333333333,
			contentLineHeightSM: 1.375,
			textTextActiveColor: 'rgba(0, 0, 0, 0.88)',
			textTextHoverColor: 'rgba(0, 0, 0, 0.88)',
			textTextColor: 'rgba(0, 0, 0, 0.88)',
			colorPrimaryBg: '#dfebf7',
			colorBgSolid: '#000000',
			colorBgSolidActive: 'rgba(0, 0, 0, 0.95)',
			colorBgSolidHover: 'rgba(0, 0, 0, 0.75)',
			colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
			solidTextColor: '#ffffff',
		},
		Card: {
			borderRadiusLG: 14,
		},
		Layout: {
			zeroTriggerHeight: 46,
			zeroTriggerWidth: 46,
			headerBg: 'rgb(255, 255, 255)',
		},
	},
};
