import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import Cookies from "js-cookie";
function usePreservedDiscountCode() {
    const searchParams = useSearchParams();
    const searchParamsDiscountCode = searchParams.get("discount");
    const [localStorageDiscountCode, setLocalStorageDiscountCode] = useLocalStorage("discountCode", {
        code: searchParamsDiscountCode,
        expiresAt: searchParamsDiscountCode ? Date.now() + 30 * 24 * 60 * 60 * 1000 : null
    });
    useEffect(()=>{
        if (!searchParamsDiscountCode) return;
        const expiresAt = Date.now() + 30 * 24 * 60 * 60 * 1000;
        setLocalStorageDiscountCode({
            code: searchParamsDiscountCode,
            expiresAt
        });
        Cookies.set("discountCode", searchParamsDiscountCode, {
            expires: new Date(expiresAt)
        });
    }, [
        searchParamsDiscountCode
    ]);
    const validDiscountCode = searchParamsDiscountCode || (localStorageDiscountCode.expiresAt && localStorageDiscountCode.expiresAt > Date.now() ? localStorageDiscountCode.code : null);
    return {
        discountCode: validDiscountCode
    };
}
export default usePreservedDiscountCode;
